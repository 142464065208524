import React from 'react'
import { WelcomeImage } from '.'

function Home() {
  return (
    <div style={{marginTop:50}}>
      <img src={WelcomeImage} width={350} />
      <h1>“Oops , looks like you’re no longer in control! Please request a new link and open it in a fresh browser window.”</h1>
    </div>
  )
}

export default Home
