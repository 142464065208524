import React, { useEffect, useState } from 'react'
import './common.css';
import { MinusImage, PlusImage, SpeedDownImage, SpeedStopImage, SpeedUpImage, TremblrLogo, RemoteSignalOff, RemoteSignalOn, ConnectionRed, ConnectionGreen } from './index';
import io from 'socket.io-client';
import { useParams } from 'react-router-dom';

const socket = io('https://server.f-machine.com', {secure: true});

function Tremblr() {
    const params = useParams();
    const { user } = params;
    const [signal, setSignal] = useState(RemoteSignalOff);
    const [username] = useState(user);
    const [machinename, setMachinename] = useState("Tremblr");
    const [status, setStatus] = useState();
    const [value, setValue] = useState(0);
    const [value2, setValue2] = useState();
    const [remotesignal, setRemotesignal] = useState(ConnectionRed);
    const [test, setTest] = useState(0);

    useEffect(() => {
        var n = parseInt(sessionStorage.getItem(username))
        if (n >= 1) {
            setTest(n);
        }

    }, [])

    //-------------- Send Massage signal-------------

    const speedUp = () => {
        if (test < value) {
            setTest(test => test + 1);
            socket.emit('send_message', { machinename: machinename, username: username, massage: "SpeedUp" });
        }
    };

    const start = () => {
        socket.emit('send_message', { machinename: machinename, username: username, massage: "Start", value2: test });
    };

    const speedDown = () => {
        if (test > 1) {
            setTest(test => test - 1);
            socket.emit('send_message', { machinename: machinename, username: username, massage: "SpeedDown" });
        }
    };

    const speedPlus = () => {
        socket.emit('send_message', { machinename: machinename, username: username, massage: "SpeedPlus" });
    };

    const speedPlusC = () => {
        socket.emit('send_message', { machinename: machinename, username: username, massage: "SpeedPlusC" });
    };

    const speedMinus = () => {
        socket.emit('send_message', { machinename: machinename, username: username, massage: "SpeedMinus" });
    };

    const speedMinusC = () => {
        socket.emit('send_message', { machinename: machinename, username: username, massage: "SpeedMinusC" });
    };

    //------------ Log Press logical code Start --------------
    const [refreshId, setRefreshId] = useState()

    const clicked = (data) => {
        var i = test;
        const myInterval = setInterval(() => {
            console.log("timer Start")
            if (data === "up") {
                if (i < value - 1) {
                    speedUp();
                    i++;
                    sessionStorage.setItem(username, i);
                    socket.emit('send_message', { machinename: machinename, username: username, massage: "Update", value2: i + 1 });
                }
            }
            if (data === "down") {
                if (i > 2) {
                    speedDown();
                    i--;
                    sessionStorage.setItem(username, i);
                    socket.emit('send_message', { machinename: machinename, username: username, massage: "Update", value2: i - 1 });
                }
            }
        }, 200);
        setRefreshId(myInterval)
    }

    const unclicked = () => {
        var i = test;
        if (i > value) {
            i = value;
        }
        setTest(test => i);
        sessionStorage.setItem(username, i);
        socket.emit('send_message', { machinename: machinename, username: username, massage: "Update", value2: i });
        clearInterval(refreshId)
    }
    //------------ Log Press logical code End --------------

    // ------------ Right click remove code-------------
    // useEffect(() => {
    //     function handleContextMenu(e) {
    //        // e.preventDefault(); // prevents the default right-click menu from appearing
    //     }
    //     // add the event listener to the component's root element
    //     const rootElement = document.getElementById('my-component');
    //     rootElement.addEventListener('contextmenu', handleContextMenu);
    //     // remove the event listener when the component is unmounted

    //     return () => {
    //         rootElement.removeEventListener('contextmenu', handleContextMenu);
    //     };
    // }, []);

    // ------------------- Receive massage signal---------------
    useEffect(() => {
        socket.on('receive_message', (msg) => {
            console.log(msg);
            if (msg.uname === username) {
                if (msg.machinestatus === true) {
                    setStatus("Start");
                    setTest(msg.value2);
                    sessionStorage.setItem(username, msg.value2);
                }
                if (msg.machinestatus === false) {
                    setStatus("Stop");
                }
                if (msg.machinestatus === "remove") {
                    window.location.href = '/';
                }
                if (msg.value == 0) {
                    sessionStorage.setItem(username, 0);
                    window.location.href = '/tremblr/' + username;
                }
                setValue(msg.value);
                setRemotesignal(ConnectionGreen);
            }
            // socket.removeListener('receive_message');
        });
        return function cleanup() {
            socket.removeListener("receive_message");
        };
    })

    return (
        <div>
            <div className='header'>
                {/* <h5 style={{marginRight:5}}>Machine : {status}</h5>
                <h5 style={{marginRight:5}}>Connection : <img src={remotesignal} alt="" className="remotesignalimage" /></h5> */}
                <h5 style={{marginRight:10}}>Speed Limit : {Math.round((value * 100) / 28)}%</h5>
                <h5>Current Speed : {status === "Stop" ? 0 : Math.round((test * 100) / 28)}%</h5>
            </div>
            <div className='main' id="my-component">
                <div className="content_bg">
                    <div className="signal">
                        <img src={signal} alt="" className="signalimage" />
                    </div>
                    <div className="buttondiv">
                        {/* --------Speed Up Button code------ */}
                        <div style={{ position: 'relative' }}>
                            <img src={SpeedUpImage}
                                alt="" className="speedUpimage" />
                            <div style={{ position: 'absolute', left: 0, top: 0, bottom: 0, right: 0, zIndex: 1000, }}
                                onKeyDown={() => { clicked("up"); speedUp(); }}
                                onKeyUp={unclicked}
                                onPointerLeave={() => { setSignal(RemoteSignalOff); unclicked(); }}
                                onPointerDown={() => { setSignal(RemoteSignalOn); clicked("up"); speedUp(); }}
                                onPointerUp={() => { setSignal(RemoteSignalOff); unclicked() }}
                            >
                            </div>
                        </div>

                        {/* --------Start Stop Button code------ */}
                        <div style={{ position: 'relative' }}>
                            <img src={SpeedStopImage}
                                alt="" className="startStopimage" />

                            <div style={{ position: 'absolute', left: 0, top: 0, bottom: 0, right: 0, zIndex: 1000, }}
                                onClick={start}
                                onKeyUp={unclicked}
                                onPointerLeave={() => { setSignal(RemoteSignalOff); unclicked(); }}
                                onPointerDown={() => setSignal(RemoteSignalOn)}
                                onPointerUp={() => setSignal(RemoteSignalOff)}
                            >
                            </div>
                        </div>

                        {/* --------Speed Down Button code------ */}
                        <div style={{ position: 'relative' }}>
                            <img src={SpeedDownImage}
                                alt="" className="speedDownimage" />
                            <div style={{ position: 'absolute', left: 0, top: 0, bottom: 0, right: 0, zIndex: 1000, }}
                                onKeyDown={() => { clicked("down"); speedDown() }}
                                onKeyUp={unclicked}
                                onPointerLeave={() => { setSignal(RemoteSignalOff); unclicked(); }}
                                onPointerDown={() => { setSignal(RemoteSignalOn); clicked("down"); speedDown() }}
                                onPointerUp={() => { setSignal(RemoteSignalOff); unclicked() }}
                            >
                            </div>
                        </div>
                    </div>
                    <div className="plusMinusdiv">
                        {/* --------Speed Minus Button code------ */}
                        <div className="Minusdiv">
                            <div style={{ position: 'relative' }}>
                                <img src={MinusImage}
                                    alt="" className="MinusImage" />
                                <div style={{ position: 'absolute', left: 0, top: 0, bottom: 0, right: 0, zIndex: 1000, }}
                                    onKeyDown={() => { speedMinus(); }}
                                    onKeyUp={speedMinusC}
                                    onPointerLeave={() => { setSignal(RemoteSignalOff); speedMinusC(); }}
                                    onPointerDown={() => { setSignal(RemoteSignalOn); speedMinus(); }}
                                    onPointerUp={() => { setSignal(RemoteSignalOff); speedMinusC(); }}
                                >
                                </div>
                            </div>
                        </div>

                        {/* --------Speed Plus Button code------ */}
                        <div className="plusdiv">
                            <div style={{ position: 'relative' }}>
                                <img src={PlusImage}
                                    alt="" className="PlusImage" />
                                <div style={{ position: 'absolute', left: 0, top: 0, bottom: 0, right: 0, zIndex: 1000, }}
                                    onKeyDown={() => { speedPlus(); }}
                                    onKeyUp={speedPlusC}
                                    onPointerLeave={() => { setSignal(RemoteSignalOff); speedPlusC(); }}
                                    onPointerDown={() => { setSignal(RemoteSignalOn); speedPlus(); }}
                                    onPointerUp={() => { setSignal(RemoteSignalOff); speedPlusC() }}
                                >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="logodiv">
                        <img src={TremblrLogo} alt="" className="logoImage" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tremblr
