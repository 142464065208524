import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Tremblr from './pages/Tremblr';
import Page404 from './pages/Page404';
import Gigolo from './pages/Gigolo';
import Alpha from './pages/Alpha';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />}/>
        <Route path='/Tremblr/:user' element={<Tremblr />} />
        <Route path='/Gigolo/:user' element={<Gigolo />} />
        <Route path='/Alpha/:user' element={<Alpha />} />
        <Route path='/*' element={<Page404 />} />
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
