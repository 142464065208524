export const RemoteBodyImage = require('./images/giglo_remote_body.png');
export const RemoteSignalOn=require('./images/giglo_remote_status_light_on.png');
export const RemoteSignalOff=require('./images/giglo_remote_status_light_off.png');
export const SpeedUpImage=require('./images/giglo_remote_up_button_not_pressed.png');
export const SpeedStopImage=require('./images/giglo_remote_stop_button_not_pressed.png');
export const SpeedDownImage=require('./images/giglo_remote_down_button_not_pressed.png');
export const MinusImage=require('./images/alpha_remote_minus_button_not_pressed.png');
export const PlusImage=require('./images/alpha_remote_plus_button_not_pressed.png');
export const TremblrLogo=require('./images/tremblr_remote_tremblr_logo.png');
export const ConnectionRed=require('./images/connection_icon_red.png');
export const ConnectionGreen=require('./images/connection_icon_green.png');
export const MenuIcon=require('./images/menu_icon.png');
export const WelcomeImage=require('./images/welcome_bg.png');
export const GigoloLogo=require('./images/giglo_remote_giglo_logo.png');
export const AlphaLogo=require('./images/alpha_remote_alpha_logo.png');
