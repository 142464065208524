import React from 'react'

function Page404() {
  return (
    <div>
      <h1>404 Page</h1>
    </div>
  )
}

export default Page404
